import { useState } from 'react';
import './Copy.css'
import { Button, Fade, InputGroup } from 'react-bootstrap';

export default function Copy({buttonText, content}) {
    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(content);
        setCopied(true);
        delay(2000).then(() => setCopied(false));
    }

    const delay = milliseconds => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    return (
        <InputGroup className='submit-input-group'>
            {copied ? (
                <InputGroup.Text>
                    Copied to Clipboard
                </InputGroup.Text>
            ) : null}
            <Button onClick={handleClick}>{buttonText || 'Copy'}</Button>
        </InputGroup>
    );
}